import React from 'react';
import { Button } from './Button';
import './HeroSection.css';
import '../App.css';

function HeroSection() {
    return (
        <div className="hero-container">
            {/* uncomment this and comment image in HersoSection.css to play video in the background */}
            {/* <video src={require('../videos/video-1.mp4').default} autoPlay loop muted /> */}
            <h1>GRAB THE LEMONS</h1>
            <p>What are you waiting for?</p>
            <div className="hero-btns">
                <Button
                    className="btns"
                    buttonStyle="btn--outline"
                    buttonSize="btn--large"
                >
                    MAKE LEMONADE
                </Button>
                <Button
                    className="btns"
                    buttonStyle="btn--primary"
                    buttonSize="btn--large"
                >
                    DRINK COCKTAILS <i className='fas fa-cocktail' />
                </Button>
            </div>
        </div>
    )
}

export default HeroSection

import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
    return (
        <div className="cards">
            <h1>Check out these awesome Cocktails!</h1>
            <div className="cards__container">
                <div className="cards__wrapper">
                    <ul className="cards__items">
                        <CardItem 
                            src={require('../images/img-2.jpg').default}
                            text="Refreshing fruits meet cool liquor."
                            label="Fresh"
                            path="/services"
                        />
                        <CardItem 
                            src={require('../images/img-3.jpg').default}
                            text="Vibrant orange, fresh mint and nice presentation."
                            label="Fresh"
                            path="/services"
                        />
                    </ul>
                    <ul className="cards__items">
                        <CardItem 
                            src={require('../images/img-4.jpg').default}
                            text="Strawberry on ice. What's better than that?"
                            label="Fruity"
                            path="/services"
                        />
                        <CardItem 
                            src={require('../images/img-5.jpg').default}
                            text="Neat Drink with a slice of orange."
                            label="Drink"
                            path="/services"
                        />
                        <CardItem 
                            src={require('../images/img-6.jpg').default}
                            text="One sour, one sweet. Enjoy both."
                            label="Fruity"
                            path="/services"
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Cards
